import { render, staticRenderFns } from "./fashion-item.vue?vue&type=template&id=e2c6a08e&scoped=true&"
import script from "./fashion-item.vue?vue&type=script&lang=js&"
export * from "./fashion-item.vue?vue&type=script&lang=js&"
import style0 from "./fashion-item.vue?vue&type=style&index=0&id=e2c6a08e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2c6a08e",
  null
  
)

export default component.exports